<template>
  <div class="page">
    <div class="box">
      <img :src="showImgSrc" alt="" class="show_img" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showImgSrc: "", //展示图片的地址
    };
  },
  created() {
    this.showImgSrc = this.$route.query.link;
  },
  methods: {
    returnsearch() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
.page {
  width: 100%;
  background-color: #fafcff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.box {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  padding: 20px 0;
}
.show_img {
  /* width: 100%; */
  border-radius: 4px;
}
</style>
